nav{
    padding: 50px;
    position: sticky;
    top: 0;
    backdrop-filter: blur(20px);
    z-index: 2;
}
nav a {
    font-style: italic;
}
.nav-links a{
    padding: 20px;
}


.nav-links a::after {
    content: '';
    width: 0px;
    height: 1px;
    display: inline-block;
    background: var(--accent);
    transition: 300ms;
}
  
.nav-links a:hover::after {
    width: 5vw;
    box-shadow: 0px -10px 20px 2px var(--accent)
}



.nav-content{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    nav{
        padding: 40px 0px 40px 40px;
    }
    .nav-links a{
        display: none;
        
    }
}