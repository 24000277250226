html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;

  background-color: var(--background);
  margin: auto;
  font-family: 'Iosevka Web', 'Helvetica Neue';
  color: var(--text);
}

.italic-thin {
  font-style: italic;
  font-weight: 200;
}

#content {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 50px;
}

a {
  text-decoration: none;
  color: var(--text);
}

h1{
  font-size: calc(15px + 5vmin);
  font-weight: 900;
}
h2{
  font-size: 32px;
  font-weight: 900;
}

p{
  line-height: 160%;
}

ul {
  list-style-type: "‣";
}

.accent{
  color: var(--accent);
}

.caption{
  opacity: 75%;
}

.section{
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  #content {
    margin: 50px 25px;
  }

  .section-header {
    width: unset;
  }

  .section-header::after {
    display: none;
  }

  .section-title {
    font-size: 32px;
  }
}