#about{
    height: 80vh;
}

.about-content img{
    width: 231px;
    height: 300px;
    border-radius: 30px;
}
.about-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-bottom: 120px;
}
.about-text{
    margin-left:90px;
    max-width: 420px;
}
.about-blue-link{
    color: var(--accent);
}

.about-blue-link::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: var(--accent);
    transition: 300ms;
  }
  
.about-blue-link:hover::after {
    width: 200px;
    box-shadow: 0px -10px 20px 2px var(--accent)
}

.about-links{
    display: flex;
    flex-wrap: wrap;

}
.about-links a{
    border: 1px solid white;
    padding: 8px 12px;
    color: var(--text);
    font-style: italic;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    margin: 14px;
}

.tech-stack{
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 10px));
}
.about-links a:hover{
    box-shadow: 6px 6px 0px 0 var(--text),0 17px 50px 0 rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 850px) {
    #about{
        height: auto;
    }
    .about-content{
        flex-direction: column;
    }
    .about-text{
        margin-top: 40px;
        margin-left:0px;
    }
}