
.experience-list{
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
}

.experience{
    padding: 24px;
    width: calc(50vw - 49px);
    box-sizing: border-box;
}
.experience > *{
    max-width: 400px;
    margin: 8px 0px;
}
.experience-right{
    width: calc(50% + 1px);

    border-left: 2px solid var(--accent);
    text-align: left;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    
}
.experience-left{
    width: calc(50% + 1px);
    border-right: 2px solid var(--accent);
    text-align: right;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.organization{
    margin-bottom: 0px;
}
.role{
    font-style: italic;
    margin-top: 0px;
}

.experience-left .experience-img{
    translate: 50px 0px;
}
.experience-right .experience-img{
    translate: -50px 0px;
}

.experience-img{
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    
}

.experience-img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.experience-duck-div{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.experience-duck{
    width: 50px;
}
.experience-duck-div p{
    font-style: italic;
    font-size: 32px;
}



@media only screen and (max-width: 700px) {
    .experience > *{
        max-width: none;
    }
    .experience{
        width: 100%;

    }
    .experience-right{
        align-self: flex-start;
    }
    .experience-left{
        border-right: 0;
        margin-right: 0;
        align-items: start;
        border-left: 2px solid var(--accent);
        text-align: left;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    .experience-left .experience-img{
        translate: -50px 0px;
    }
    .experience-duck-div{
        align-items: start;
    }
}