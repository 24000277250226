#intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 180px);
}
.intro-message{
    text-align: center;
}

.cube-container{
    display: flex;
    justify-content: center;
}
